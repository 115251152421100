<template>
    <div>
        <v-row>
            <v-col class="d-none d-sm-flex">
                <v-toolbar-title>Betriebsstätten</v-toolbar-title>
            </v-col>
            <v-col>
                <v-text-field v-model="searchValue"
                              outlined
                              x-small
                              append-icon="mdi-magnify"
                              dense
                ></v-text-field>
            </v-col>
            <v-col v-if="$vuetify.breakpoint.smAndUp" class="text-right">
                <v-btn @click="openDepartmentForm(null)" color="primary">HINZUFÜGEN</v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-data-table
                        :headers="headers"
                        :items="tableItems.data"
                        :server-items-length="tableItems.total"
                        :loading="loading"
                        class="elevation-1"
                        hide-default-footer
                >
                    <template v-slot:item.company_id="{ item }">
                        <div v-if="item.company">
                            <a @click.prevent="$root.$children[0].$refs['company-form'].open(item.company.id, true)"
                               href="#">{{ item.company.name }}</a>
                        </div>
                        <div v-else>-</div>
                    </template>
                    <template v-slot:item.actions="{ item }">
                        <v-btn @click="openDepartmentForm(item.id, true)" small icon>
                            <v-icon>
                                mdi-eye
                            </v-icon>
                        </v-btn>
                        <v-btn @click="openDepartmentForm(item.id)" small icon color="success">
                            <v-icon>
                                mdi-pencil
                            </v-icon>
                        </v-btn>
                        <v-btn @click="deleteDepartment(item.id)" small icon color="accent">
                            <v-icon>
                                mdi-delete
                            </v-icon>
                        </v-btn>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import ConstructionZoneForm from "../components/ConstructionZoneForm"
    import Paginate from '@/mixins/paginate'
    import {departmentsPaginated, removeDepartment} from './../graphql/department'

    export default {
        components: {ConstructionZoneForm},
        mixins: [Paginate],
        data() {
            return {
                loading: false,
                fetchQuery: departmentsPaginated,
                modelName: 'departments',
                headers: [
                    {
                        text: 'Id',
                        sortable: false,
                        value: 'id',
                    },
                    {
                        text: 'Name',
                        sortable: false,
                        value: 'name',
                    },
                    {
                        text: 'Nummer',
                        sortable: false,
                        value: 'number',
                    },
                    {
                        text: 'Firmen',
                        sortable: false,
                        value: 'company_id',
                    },
                    {
                        text: '',
                        sortable: false,
                        value: 'actions',
                        width: '120px'
                    }
                ],
            };
        },
        methods: {
            openDepartmentForm(id, readonly = false) {
                this.$root.$children[0].$refs['department-form'].open(id, readonly, () => {
                    this.$apollo.queries.tableItems.refetch()
                })
            },
            deleteDepartment(id) {
                this.$root.$children[0].$refs.confirm.open('Delete', 'Sind Sie sicher das Sie entfernen möchten?', {color: 'accent'}).then((confirm) => {
                    if (confirm) {
                        this.$apollo.mutate({
                            mutation: removeDepartment,
                            variables: {id},
                            update: (store, {data: {removeDepartment}}) => {
                                this.tableItems.data = this.tableItems.data.filter(cz => {
                                    return removeDepartment.id != cz.id
                                })
                            }
                        })
                    }
                })
            },
        },
        mounted: function () {
            this.$root.$children[0].bottomMenu = [
                {
                    icon: 'add',
                    event: () => {
                        this.openDepartmentForm(null)
                    }
                }
            ]
        }
    }
</script>